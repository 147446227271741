<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>
    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Categorías',
              quantity: data.counters.categories.total,
              icon: 'chess',
              change: data.counters.categories.actives,
              changeVar: 'categorías activas'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Academias',
              quantity: data.counters.academies.total,
              icon: 'shield-alt',
              change: data.counters.academies.actives,
              changeVar: 'equipos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <!--<div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Jugadores',
              quantity: data.counters.players.total,
              icon: 'users',
              change: data.counters.players.actives,
              changeVar: 'jugadores activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Partidos',
              quantity: data.counters.games.total,
              icon: 'volleyball-ball',
              change: data.counters.games.actives,
              changeVar: 'partidos activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>-->
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row align-center">
          <!--<div class="my-auto mx-2">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>-->
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_category"
              placeholder="Categoría"
              :options="categories"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_subcategory"
              placeholder="Subcategoría"
              :options="subcategories"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          class="my-auto mr-2 h-fit py-2"
          @click="$router.push('/torneos/'+$route.params.id+'/categorias/crear')"
        >
          Añadir categorías
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="tournamentCategory"
          :headers="headers"
          :url="'torneos/'+$route.params.id+'/categorias'"
          :data="tournamentCategories"
          :route="'/tournament_category'"
          :tableName="'CATEGORÍAS'"
          :parameters="parameters"
          :hasDetail="false"
          :hasDetail4="true"
          :canEdit="false"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
//import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    //Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "Categoría",
        "Subcategoría",
        "Equipos",
        "Acciones"
      ],
      data: {
        counters: {
          academies: {},
          categories: {},
        }
      },
      query: "",
      parameters: {
        order_key: 'id_category',
        order_value: 'asc',
        id_tournament: this.$route.params.id,
        id_category: null,
        id_subcategory: null
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    categories() {
      return this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
    },
    subcategories() {
      return this.$store.getters["global/getItems"](
        "subcategory",
        "unpaginated_elements"
      )
    },
    tournamentCategories() {
      return this.$store.getters["global/getItems"](
        "tournamentCategory",
        "elements"
      ).map((el) => ({
        id: el.id,
        category: el.category ? el.category.name : '',
        subcategory: el.subcategory ? el.subcategory.name : '',
        teams: el.teams,
        active: el.active
      }))
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/tournament/"+this.$route.params.id+"/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.getCounters()
    },
    activate() {
      this.getCounters()
    },
    erase() {
      this.getCounters()
    }
  },
  created() {
    this.getCounters()
    this.getItems("/category/all", "category", true)
    this.getItems("/subcategory/all", "subcategory", true)
  }
}
</script>
